import { PageProps } from 'gatsby';
import { ChannelData } from 'packages/innedit';
import React, { FC } from 'react';

import CMSView from '~/components/View';
import Form from '~/containers/Espace/Form';
import channelParams from '~/params/channel/attributes.json';
import TemplateAdmin from '~/templates/admin';
import requireUser, { UserProps } from '~/utils/requireUser';
import useChannel from '~/utils/useChannel';
import useEspace from '~/utils/useEspace';

const EspaceCanalUpdate: FC<PageProps & UserProps> = function ({
  location,
  params: { channelId, id: espaceId },
}) {
  const [espace] = useEspace({ id: espaceId, pathname: location.pathname });
  const [channel, items] = useChannel({
    channelId,
    espaceId,
    admin: true,
    pathname: location.pathname,
  });

  return (
    <TemplateAdmin>
      <CMSView>
        <Form
          displayAdd={false}
          displayArchive={false}
          displayNavigation={false}
          displayRemove={false}
          docId={channelId}
          itemPathnamePrefix={`/admin/espaces/${espaceId}/channels/`}
          model={
            new ChannelData({
              espaceId,
              params: channelParams,
            })
          }
          name="item"
          params={{ channelId }}
          subMenu={items}
          title={`${espace?.label} ⇾ ${channel?.label}`}
          type="update"
        />
      </CMSView>
    </TemplateAdmin>
  );
};

export default requireUser(EspaceCanalUpdate, 'admin');
